<template>
  <div>

    <h1 class="mb-5">Gestión de usuarios</h1>

    <v-dialog
      v-model="dialogDelete"
      width="400"
    >
      <v-card>
        <v-card-title class="title primary white--text">
          Borrar Institución
          <v-spacer></v-spacer>
          <v-btn
            @click="dialogDelete = false"
            icon
          >
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-5 text-center">
          <p>¿Estás seguro de borrar al usuario?</p>
          <i><b>{{ dialogDeleteName }}</b></i>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="deleteUser"
          >
            Borrar
          </v-btn>          
          <v-btn
            color="primary"
            text
            @click="dialogDelete = false"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogUpdate"
      width="500"
    >
      <v-card
        :loading="formUpdateLoading"
      >
        <template slot="progress">
          <v-progress-linear
            color="secondary"
            height="2"
            indeterminate
          ></v-progress-linear>
        </template>
        <v-card-title class="title primary white--text">
          Editar Usuario
          <v-spacer></v-spacer>
          <v-btn
            @click="dialogUpdate = false"
            icon
          >
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <validation-observer ref="formUpdateRef">
          <v-form slot-scope="{ invalid, validated }">          
            <v-card-text class="pt-5">

              <validation-provider name="usr_color" rules="required">
                <v-color-picker                          
                  v-model="formUpdate.usr_color"
                  :value="formUpdate.usr_color"
                  slot-scope="{ errors, valid }"
                  :error-messages="errors"
                  :success="valid"
                  hide-canvas
                  hide-inputs
                  hide-sliders
                  show-swatches
                  class="mb-8"
                  swatches-max-height="80"
                  width="468"
                ></v-color-picker>
              </validation-provider>  

              <v-row>
                <v-col md="2">
                  <v-avatar 
                    class="elevation-5"  
                    :color="formUpdate.usr_color"
                  >
                    <h2 class="font-weight-regular white--text">{{ formUpdate.usr_initials }}</h2>
                  </v-avatar>
                </v-col>
                <v-col>
                  <validation-provider name="usr_initials" rules="required|max:2">                 
                    <v-text-field
                      slot-scope="{ errors, valid }"
                      v-model="formUpdate.usr_initials"
                      :error-messages="errors"
                      :success="valid"
                      label="Iniciales"
                      placeholder="Iniciales" 
                      hint="2 caracteres"
                      maxlength="2"
                      required                       
                      dense
                      filled
                    ></v-text-field>
                  </validation-provider> 
                </v-col>
              </v-row>  

              <validation-provider name="usr_name" rules="required|min:3">                 
                <v-text-field
                  slot-scope="{ errors, valid }"
                  v-model="formUpdate.usr_name"
                  :error-messages="errors"
                  :success="valid"
                  label="Nombre de usuario"
                  placeholder="Nombre de usuario" 
                  autocomplete="username"
                  required                       
                  dense
                  filled
                ></v-text-field>
              </validation-provider>
              <validation-provider name="formUpdate.usr_email" rules="required|email">
                <v-text-field
                  slot-scope="{ errors, valid }"
                  v-model="formUpdate.usr_email"
                  :error-messages="errors"
                  :success="valid"
                  label="Email"
                  placeholder="Email"
                  autocomplete="email"
                  dense
                  filled
                ></v-text-field>
              </validation-provider>
              <validation-provider name="formUpdate.usr_role" rules="required">
                <v-select
                  slot-scope="{ errors, valid }"
                  v-model="formUpdate.usr_role"
                  :items="userTypes"
                  :error-messages="errors"
                  :success="valid"     
                  label="Tipo"
                  clearable
                  dense
                  filled
                ></v-select>
              </validation-provider>
              <validation-provider name="formUpdate.usr_hash" rules="min:6">
                <v-text-field
                  slot-scope="{ errors, valid }"
                  v-model="formUpdate.usr_hash"
                  :error-messages="errors"
                  :success="valid"
                  :counter="true"
                  label="Contraseña"
                  placeholder="Contraseña"
                  type="password"
                  autocomplete="new-password"
                  dense
                  filled
                ></v-text-field>
              </validation-provider>
              <validation-provider name="usr_hash_verify" rules="min:6|password:@formUpdate.usr_hash">
                <v-text-field
                  slot-scope="{ errors, valid }"
                  v-model="usr_hash_verify"
                  :error-messages="errors"
                  :success="valid"
                  label="Confirmación de la contraseña"
                  placeholder="Confirmación de la contraseña"
                  type="password"
                  autocomplete="new-password"
                  dense
                  filled
                ></v-text-field>
              </validation-provider>     
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="updateUser"
                :disabled="invalid || !validated"
                color="secondary"
                text            
              >
                Actualizar
              </v-btn>          
              <v-btn
                color="primary"
                text
                @click="dialogUpdate = false"
              >
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-form>
        </validation-observer>        
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="12">
        <v-expansion-panels class="">
          <v-expansion-panel>
            <v-expansion-panel-header class="secondary white--text">
              <div><v-icon class="mr-3 subtitle-1" color="white">mdi-plus-box-outline</v-icon><span class="font-weight-medium">Añadir usuario</span></div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pt-5">
              <validation-observer ref="insertForm">
                <v-form slot-scope="{ invalid, validated }">
                  <v-row>
                    <v-col md="3">
                      <h4>Color:</h4>
                      <validation-provider name="usr_color" rules="required">
                        <v-color-picker                          
                          v-model="form.usr_color"
                          slot-scope="{ errors, valid }"
                          :error-messages="errors"
                          :success="valid"
                          hide-canvas
                          hide-inputs
                          hide-sliders
                          show-swatches
                          swatches-max-height="133"
                        ></v-color-picker>
                      </validation-provider>
                    </v-col>
                    <v-col md="9">                 
                      <v-row>
                        <v-col cols="12" sm="6" md="2">   
                          <validation-provider name="usr_initials" rules="required|max:2">                 
                            <v-text-field
                              slot-scope="{ errors, valid }"
                              v-model="form.usr_initials"
                              :error-messages="errors"
                              :success="valid"
                              label="Iniciales"
                              placeholder="Iniciales" 
                              hint="2 caracteres"
                              maxlength="2"
                              required                       
                              dense
                              filled
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">   
                          <validation-provider name="usr_name" rules="required|min:3">                 
                            <v-text-field
                              slot-scope="{ errors, valid }"
                              v-model="form.usr_name"
                              :error-messages="errors"
                              :success="valid"
                              label="Nombre de usuario"
                              placeholder="Nombre de usuario" 
                              required                       
                              dense
                              filled
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <validation-provider name="form.usr_email" rules="required|email">
                            <v-text-field
                              slot-scope="{ errors, valid }"
                              v-model="form.usr_email"
                              :error-messages="errors"
                              :success="valid"
                              label="Email"
                              placeholder="Email"
                              dense
                              filled
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                          <validation-provider name="form.usr_role" rules="required">
                            <v-select
                              slot-scope="{ errors, valid }"
                              v-model="form.usr_role"
                              :items="userTypes"
                              :error-messages="errors"
                              :success="valid"     
                              label="Tipo"
                              clearable
                              dense
                              filled
                            ></v-select>
                          </validation-provider>
                        </v-col>
                        
                        <v-col cols="12" sm="6" md="4">
                          <validation-provider name="form.usr_hash" rules="required|min:6">
                            <v-text-field
                              slot-scope="{ errors, valid }"
                              v-model="form.usr_hash"
                              :error-messages="errors"
                              :success="valid"
                              :counter="true"
                              label="Contraseña"
                              placeholder="Contraseña"
                              type="password"
                              dense
                              filled
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <validation-provider name="usr_hash_verify" rules="required|min:6|password:@form.usr_hash">
                            <v-text-field
                              slot-scope="{ errors, valid }"
                              v-model="usr_hash_verify"
                              :error-messages="errors"
                              :success="valid"
                              label="Confirmación de la contraseña"
                              placeholder="Confirmación de la contraseña"
                              type="password"
                              dense
                              filled
                            ></v-text-field>
                          </validation-provider>
                        </v-col>

                        <v-col cols="12" sm="6" md="4">
                          <v-switch
                            v-model="form.usr_active"
                            label="Activado"
                            :true-value="1"
                            :false-value="0"
                            dense
                          ></v-switch>
                        </v-col>                        
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="text-center">                    
                      <v-btn
                        @click="addUser"
                        :disabled="invalid || !validated"
                        color="primary"
                      >
                        Añadir Usuario
                      </v-btn>
                      <v-btn
                        @click="resetForm"
                        color="red white--text"
                        class="ml-3"
                      >
                        Reset
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </validation-observer>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>

      <v-col cols="12">
        <v-card class="mt-5">
          <v-card-title class="mb-5">
            <v-icon class="mr-2" color="primary">mdi-view-list</v-icon>Listado
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>

            <v-text-field
              v-model="search"
              append-icon="mdi-table-search"
              label="Buscar"
              class="white mr-2"
              clearable
              hide-details
              outlined
              dense
            ></v-text-field> 

            <v-btn
              @click="loadUsuarios"
              color="primary lighten-3"
              height="36" width="38"
              elevation="1" 
              x-small
            >
              <v-icon color="black" small>mdi-table-refresh</v-icon>
            </v-btn> 
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="usuarios"
            :search="search"
            :loading="loadingUsers"
            loading-text="Cargando Datos..."        
            item-key="usr_id"
            :items-per-page="100"
            :footer-props="{ itemsPerPageOptions: [25,50,100,-1] }"
          >
            <template v-slot:[`item.usr_initials`]="{ item }">
              <v-avatar 
                class="elevation-5"  
                :color="item.usr_color"
                size="35"
              >
                <h4 class="font-weight-regular white--text">{{ item.usr_initials }}</h4>
              </v-avatar>
            </template>

            <template v-slot:[`item.usr_last_login_date`]="{ item }"> 
              {{ convertDate(item.usr_last_login_date, true) }}
            </template>
            <template v-slot:[`item.usr_insert_date`]="{ item }"> 
              {{ convertDate(item.usr_insert_date, true) }}
            </template>
            <template v-slot:[`item.usr_update_date`]="{ item }"> 
              {{ convertDate(item.usr_update_date, true) }}
            </template>

            <template v-slot:[`item.usr_active`]="{ item }">
              <v-switch
                :input-value="item.usr_active"
                @click="setUserActiveValue(item)"
                :loading="item.loading" 
                readonly               
                dense
              ></v-switch>
            </template>
          
            <template v-slot:[`item.opciones`]="{ item }"> 
              <a href.prevent="" @click="setUpdateDialog(item)">Editar</a> -
              <a href.prevent="" @click="setDeleteDialog(item)">Borrar</a>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { ValidationProvider, ValidationObserver, withValidation } from "vee-validate"
  import { mixinGlobal } from '@/mixins/mixinGlobal'
  
  import { mapGetters } from 'vuex'

  export default {
    name: 'UserManagement',
    components: { ValidationProvider, ValidationObserver },
    mixins: [ mixinGlobal ],
    data: () => ({
      usuarios: [],
      loadingUsers: false,
      valid: false,
      dialogDelete: false,
      dialogDeleteName: '',
      dialogDeleteItem: '',
      dialogUpdate: false,
      dialogUpdateItem: '',
      searchTag: null,
      tagList: [],
      user: {
        usr_initials: '',
        usr_name: '',
        usr_color: '',
        usr_email: '',
        usr_role: '',
        usr_hash: '',
        usr_active: 0
      },
      usr_hash_verify: '',
      form: {},
      formUpdate: {},
      formUpdateLoading: false,
      search: '',
      headers: [
        { text: 'ID', value: 'usr_id', align: 'right', width: 70 },
        { text: 'Avatar', value: 'usr_initials', sortable: false },
        { text: 'Nombre', value: 'usr_name' },
        { text: 'Email', value: 'usr_email' },       
        { text: 'Útlimo login', value: 'usr_last_login_date' },        
        { text: 'Creado', value: 'usr_insert_date' },
        { text: 'Actualizado', value: 'usr_update_date' },
        { text: 'Tipo', value: 'usr_role' },
        { text: 'Activo', value: 'usr_active' },
        { text: 'Opciones', value: 'opciones', align: 'center', sortable: false, width: 120 },
      ],
      userTypes: ['admin', 'editor', 'suscriber'],
      
    }),
    mounted () {
      this.loadUsuarios()
      this.form = Object.assign({}, this.user)
      this.formUpdate = Object.assign({}, this.user)
    },
    computed: {
      ...mapGetters('auth', ['getUserData']),
    },
    methods: {
      loadUsuarios(){
        this.loadingUsers = true
        this.usuarios = []
        this.$axios.get('usr_usuarios?order=usr_name')
        .then( (respuesta) =>{  
          this.usuarios = respuesta.data.data      
          this.usuarios.forEach(element => {
            this.$set(element, 'loading', false)
          });    
          this.loadingUsers = false
        })  
        .catch( (error) =>{
          let  message = '';
          if (error.response.data.hasOwnProperty('message')) {
            message = error.response.data.message;
          }else{
            message = error
          }
          this.$store.commit('messageHandler/SET_MESSAGE', { status: 400, statusText: message } )	
        })    
      },
      setUserActiveValue(item){
        let data = {}
        data.usr_id = item.usr_id
        data.usr_update_date = this.getMysqlDate()
        data.usr_update_usrid = this.getUserData.usr_id
        data.usr_active = item.usr_active ? 0 : 1

        item.loading = true

        this.$axios.put('usr_usuarios', data)
        .then( (respuesta) =>{  
          item.loading = false
          item.usr_active = respuesta.data.data[0].usr_active 
          item.usr_update_date = respuesta.data.data[0].usr_update_date
          this.$store.commit('messageHandler/SET_MESSAGE', { status: 200, statusText: respuesta.data.message } )	
        })  
        .catch( (error) =>{
          let  message = '';
          if (error.response.data.hasOwnProperty('message')) {
            message = error.response.data.message;
          }else{
            message = error
          }
          this.$store.commit('messageHandler/SET_MESSAGE', { status: 400, statusText: message } )	
        })
      },
      async addUser(){
        const valid = await this.$refs.insertForm.validate();
        if (valid){
          // SET DATE
          //console.log(this.getUserData)
          this.form.usr_insert_date = this.getMysqlDate()
          this.form.usr_insert_usrid = this.getUserData.usr_id

          //console.log(this.form)

          this.$axios.post('usr_usuarios', this.form)
          .then( (respuesta) =>{  
            this.form = Object.assign({}, this.user)
            this.loadUsuarios()
            this.$store.commit('messageHandler/SET_MESSAGE', { status: 200, statusText: 'Usuario añadido con éxito!' } )	
            this.resetForm()
          })  
          .catch( (error) =>{
            let  message = '';
            if (error.response.data.hasOwnProperty('message')) {
              message = error.response.data.message;
            }else{
              message = error
            }
            this.$store.commit('messageHandler/SET_MESSAGE', { status: 400, statusText: message } )	
          })
        }else{
          this.$store.commit('messageHandler/SET_MESSAGE', { status: 400, statusText: 'Los datos de usuario no son válidos.' } )	
        }
      },
      resetForm() {
        this.form = Object.assign({}, this.user)
        this.usr_hash_verify = ''
        this.$nextTick(() => {
          this.$refs.insertForm.reset()
        });
      },
      setDeleteDialog(item){
        this.dialogDeleteName = item.usr_name
        this.dialogDeleteItem = item
        this.dialogDelete = true
      },
      deleteUser(){
        this.$axios.delete('usr_usuarios?usr_id=' + this.dialogDeleteItem.usr_id )
        .then( (respuesta) =>{  
          this.loadUsuarios()           
          this.$store.commit('messageHandler/SET_MESSAGE', { status: 200, statusText: 'Usuario borrado con éxito!' } )	
          this.dialogDeleteItem = ''
          this.dialogDelete = false
        })  
        .catch( (error) =>{
          let  message = '';
          if (error.response.data.hasOwnProperty('message')) {
            message = error.response.data.message;
          }else{
            message = error
          }
          this.$store.commit('messageHandler/SET_MESSAGE', { status: 400, statusText: message } )	
        })
      },
      setUpdateDialog(item){
        this.formUpdate = Object.assign({}, item)
        this.$delete(this.formUpdate, 'loading')
        this.formUpdate.usr_hash = ''
        this.dialogUpdate = true
        this.$nextTick(() => {
          this.$refs.formUpdateRef.validate()
        });
        
      },
      updateUser(){
        this.formUpdateLoading = true

        // UPDATE
        if( this.formUpdate.usr_hash.length < 6 ){
          this.$delete(this.formUpdate, 'usr_hash')
        }

        // SET DATE
        this.formUpdate.usr_update_date = this.getMysqlDate()
        this.formUpdate.usr_update_usrid = this.getUserData.usr_id

        // DELETE => ONLY API CAN MODIFY THIS DATA
        delete this.formUpdate.usr_last_login_date

        this.$axios.put('usr_usuarios/update', this.formUpdate )
        .then( (respuesta) =>{  
          this.formUpdate = Object.assign({}, this.user)
          this.loadUsuarios()
          this.$store.commit('messageHandler/SET_MESSAGE', { status: 200, statusText: 'Usuario actualizado con éxito!' } )	
          this.formUpdateLoading = false
          this.dialogUpdate = false
        })  
        .catch( (error) =>{
          let  message = '';
          if (error.response.data.hasOwnProperty('message')) {
            message = error.response.data.message;
          }else{
            message = error
          }
          this.$store.commit('messageHandler/SET_MESSAGE', { status: 400, statusText: message } )	
          this.formUpdateLoading = false
        })
      }
    },
    watch: {
      dialogUpdate(newValue, oldValue) {
        this.usr_hash_verify = ''
      }
    },
  }  
</script>